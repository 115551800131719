import Modal from "@src/libs/ui/Modal";
import ConfigStore, { Totem } from "@src/model/config";
// import { connectTotem, getTotem, initTotem } from "@src/services/Totem";

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Icon from "react-icons-kit";
import Item from "../queue/Item";
import {sphere} from 'react-icons-kit/icomoon/sphere'
import {androidCheckboxBlank} from 'react-icons-kit/ionicons/androidCheckboxBlank'
import {stop} from 'react-icons-kit/entypo/stop'
import {ic_dvr} from 'react-icons-kit/md/ic_dvr'
import { runInAction } from "mobx";

const TopBarTotem = observer(() => {
    var totem=""
    useEffect(() => {
        totem=ConfigStore.selectedTotem.name
    }, [ConfigStore.showListTotem]);
  return (
    <div className="flex items-center ">
    <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 ml-0 h-8"
    style={{
      backgroundColor:ConfigStore.socketIsConnected!==1?"#ff4f4f":ConfigStore.selectedTotem.room!==""?"#38a169":"#4a5568"
    }}
    >
    <button
            className="p-1 rounded-md flex justify-center items-center ripple"
            onClick={() => {
              runInAction(() => {
                ConfigStore.showListTotem=true
              });
            }}
          >
        
        {/* <div className="flex flex-row items-center text-left truncate  border-1 text-gray-700 border-gray-700 rounded font-bold">
        Totem
      </div> */}

        <Icon
        icon={ic_dvr}
        size={24}
        style={{
          backgroundSize: 24,
          color: "#fff",
        }}
      />
          
      
      </button>

      

     </div>
     </div>
  );
});


export default TopBarTotem;
